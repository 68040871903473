import type { NextPage, GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import nextI18NextConfig from '../next-i18next.config'
import Link from 'next/link'

export const Page404: NextPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex font-poppins min-h-full flex-col bg-white pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold uppercase tracking-wide text-primary-blue-900">
              {' '}
              {t('ErrorPage.error404.title')}
            </p>
            <h1 className="mt-2 text-5xl font-semibold tracking-tight text-primary-blue-900-text sm:text-5xl">
              {' '}
              {t('ErrorPage.error404.subtitle')}
            </h1>
            <p className="mt-4 text-base text-secondary-text">
              {' '}
              {t('ErrorPage.error404.description')}
            </p>
            <div className="mt-6">
              <Link href="/">
                <a className="text-base font-medium text-primary-blue-900 hover:opacity-80">
                  {t('ErrorPage.error404.actionButton')}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Page404

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ['common'], nextI18NextConfig))
    }
  }
}